import React, { useState } from "react";
import { useForm } from "./StateManagement";
import { generateId } from "../../utils/genIds";

export const CustomFormFields = () => {
  const { state } = useForm();
  const CompMap = {
    text: CustomInput,
    checkbox: Checkbox,
    "drop down": CustomDropDown,
  };

  return (
    <div className="grid grid-cols-1 gap-x-4 mt-1 sm:grid-cols-6">
      {state.fundFormFields.map((ff, i) => {
        const C = CompMap[ff.input_type] || CustomInput;
        return <C key={i} formField={ff} />;
      })}
    </div>
  );
};

export const CustomDropDown = ({ formField }) => {
  const { state, updateFormValue } = useForm();
  const { formSelectOptions } = state;

  const options = formSelectOptions.filter(
    (o) => o.form_field_id === formField.id
  );

  const handleSelectChange = (e) => {
    e.preventDefault();

    updateFormValue(formField, e.target.value);
  };

  return (
    <div className="py-2 sm:col-span-3">
      <label className="block text-sm leading-5 font-medium text-gray-700">
        {formField.label}
        {formField.required ? "*" : ""}
      </label>
      <select
        required={formField.required}
        onChange={handleSelectChange}
        className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:ring-blue focus:border-blue-300 sm:text-sm sm:leading-5"
      >
        <option value="" disabled selected hidden>
          Choose one
        </option>
        {options.map((o) => (
          <option key={o.id} value={o.value}>
            {o.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export const Checkbox = ({ formField }) => {
  const { updateFormValue } = useForm();
  const handleChange = (e) => {
    updateFormValue(formField, !formField.value);
  };

  return (
    <>
      <div className="py-2 sm:col-span-3">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {" "}
          {formField.label}
        </label>
        <div className="mt-1">
          <input
            type="checkbox"
            checked={formField.value}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export const CustomInput = ({ formField }) => {
  const { updateFormValue } = useForm();
  const handleChange = (e) => {
    updateFormValue(formField, e.target.value);
  };

  return (
    <>
      <div className="py-2 sm:col-span-3">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {" "}
          {formField.label}
          {formField.required ? "*" : ""}
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            type={formField.input_type}
            value={formField.value || ""}
            name={formField.name}
            placeholder={formField.placeholder}
            required={formField.required}
            maxLength={40}
            onChange={handleChange}
            className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5 "
          />
        </div>
      </div>
    </>
  );
};

export const Input = ({
  type,
  label,
  name,
  isRequired,
  selectOptions = null,
}) => {
  const { updateUserAttribute, state } = useForm();
  const { user, errors } = state;

  const handleChange = (e) => {
    updateUserAttribute(name, e.target.value);
  };
  const myerrors = errors[name] || [];

  const renderDropdown = () => {
    return (
      <select
        type={type}
        value={user[name] || ""}
        name={name}
        required={isRequired}
        onChange={handleChange}
        className="form-select block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5 "
      >
        <option value="" disabled hidden>
          Choose one
        </option>
        {selectOptions.length > 0 &&
          selectOptions.map((o, i) => (
            <option key={i} value={o.value}>
              {o.text}
            </option>
          ))}
      </select>
    );
  };

  return (
    <>
      <div className="py-2 sm:col-span-3">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {" "}
          {label}
          {isRequired ? "*" : ""}
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          {type === "dropdown" ? (
            renderDropdown()
          ) : (
            <input
              type={type}
              value={user[name] || ""}
              name={name}
              required={isRequired}
              onChange={handleChange}
              className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5 "
            />
          )}

          {myerrors.length > 0 ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
        </div>
        {myerrors.map((e, i) => {
          return (
            <p key={i} className="mt-2 text-sm text-red-600">
              {e}
            </p>
          );
        })}
      </div>
    </>
  );
};

export const SubmitBtn = () => {
  const { state, stripe } = useForm();
  const { amount, fund, loading } = state;

  const btnLoading = !stripe || loading;
  const buttonStyles =
    btnLoading || amount < 1 || isNaN(amount)
      ? "w-full text-center text-xl px-3 py-4 mx-auto border border-transparent text-md leading-4 font-body font-medium rounded-md text-gray-800 bg-yellow-200 focus:outline-none transition ease-in-out "
      : "w-full text-center text-xl px-3 py-4 mx-auto border border-transparent text-md leading-4 font-body font-medium rounded-md text-gray-800 bg-yellow-600 hover:bg-yellow-800 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition ease-in-out duration-150";

  const buttonText = fund.form_submit_text;

  return (
    <button
      type="submit"
      disabled={btnLoading || amount < 1}
      className={buttonStyles}
    >
      {btnLoading ? "Loading…" : buttonText}
    </button>
  );
};

export const AmountInput = () => {
  const { state, updateAmount } = useForm();
  const { fund, errors, amount, currentDueLevel } = state;

  const amountErrors = errors["amount"] || [];

  const onChange = (e) => {
    updateAmount(e.target.value);
  };

  const preventScroll = (event) => {
    event.target.blur();
    event.stopPropagation();
    setTimeout(() => {
      event.target.focus();
    }, 0);
  };

  const label =
    fund.fund_type === "misc_dues" || currentDueLevel?.id === "custom_amount"
      ? "Amount *"
      : `Donation Amount (${fund.amount_currency})*`;

  return (
    <>
      <div className="py-2 sm:col-span-6">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {label}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span className="text-gray-500 sm:text-sm sm:leading-5">$</span>
          </div>
          <input
            required
            type="number"
            step=".01"
            // min="5"
            onWheel={preventScroll}
            value={amount || ""}
            onChange={onChange}
            className="block w-full mt-1 py-2 px-3 pr-12 pl-7 sm:text-sm sm:leading-5 border border-gray-300 rounded-md shadow-sm"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span className="text-gray-500 sm:text-sm sm:leading-5">{fund.amount_currency}</span>
          </div>
        </div>
        {amountErrors.map((e, i) => {
          return (
            <p key={i} className="mt-2 text-sm text-red-600">
              {e}
            </p>
          );
        })}
      </div>
    </>
  );
};

export const DuesAmountInput = () => {
  const { updateDueLevel, setCustomDueLevel, state } = useForm();
  const { currentDueLevel, fund, dueLevels, autoPay, subscription_future_start } = state;

  const handleChange = (e) => {
    const { value } = e.target;

    value === "custom_amount"
      ? setCustomDueLevel(value)
      : updateDueLevel(value);
  };

  return (
    <>
      <div className="py-2 sm:col-span-6">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          Select one
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select
            onChange={handleChange}
            defaultValue={currentDueLevel.id}
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          >
            {dueLevels.map((dl, i) => {
              return (
                <option value={dl.id} key={i}>
                  {dl.name} - ${dl.amount.toFixed(2)}
                  {fund.amount_currency != "USD" && ` ${fund.amount_currency}`}
                </option>
              );
            })}

            {fund.allow_custom_amount && (
              <option value="custom_amount">Custom Amount</option>
            )}
          </select>
        </div>
        <AutoPayInput />
        {currentDueLevel.recurring && subscription_future_start && <AutoPayScheduleInput />}
      </div>
    </>
  );
};

// add a component where we can add a start date for recurring payments
const AutoPayScheduleInput = () => {
  const { state, dispatch } = useForm();
  const { currentDueLevel, autoPay, subscription_future_start, future_start_date, future_start_date_enabled } = state;
  // const [futureStart, setFutureStart] = useState(true);
  const setFutureStartDate = (value) => {
    dispatch({ type: "UPDATE_FUTURE_START_DATE", date: value })
  }
  const toggleFutureStartEnabled = () => {
    dispatch({ type: "TOGGLE_FUTURE_START_DATE_ENABLED" })
  }

  const id = generateId();

  // gets today and formats it to 2024-01-01 for the date field min attribute
  const minDate = new Date().toISOString().split('T')[0];
  // gets today and adds 60 days to it and formats it to 2024-01-01 for the date field max attribute
  const maxDate = new Date(new Date().setDate(new Date().getDate() + 60)).toISOString().split('T')[0];
  return <>
    <div className="flex items-center pt-4">

      <input
        id={id}
        type="checkbox"
        onChange={toggleFutureStartEnabled}
        checked={future_start_date_enabled}
        className="border border-gray-300 rounded-sm shadow-sm"
      />
      <label htmlFor={id} className="ml-1 text-sm">
        Start On a Future Date?
      </label>
    </div>
    {future_start_date_enabled &&
      <div className="flex flex-col">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700" htmlFor="start_date">Start Date</label>
        <input id="start_date"
          onChange={(e) => { setFutureStartDate(e.target.value) }}
          value={future_start_date}
          min={minDate}
          max={maxDate}
          className="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5" type="date" name="startdate" />
      </div>
    }
  </>
}


const AutoPayInput = () => {
  const { updateAutoPay, state } = useForm();
  const { currentDueLevel, autoPay, force_autopay } = state;
  const id = generateId();
  const handleChange = () => {
    updateAutoPay(!autoPay);
  };

  const [show, setShow] = useState(false);

  const englishInterval = {
    "weekly": "week",
    "bi-weekly": "two weeks",
    "monthly": "month",
    "quarterly": "quarter",
    "yearly": "year",
  };

  return (
    <>
      {currentDueLevel.recurring && (
        !!force_autopay ? (
          <p className="p-2 text-sm">
            By completing this transaction, you acknowledge and understand this is a {currentDueLevel.interval} recurring payment.
            This means you'll be charged automatically every {englishInterval[currentDueLevel.interval]}, starting from the future date you specify below.
          </p>
        ) :
          <>
            <div className="flex items-center pt-4">
              <input
                id={id}
                type="checkbox"
                onChange={handleChange}
                checked={autoPay}
                className="border border-gray-300 rounded-sm shadow-sm"
              />
              <label htmlFor={id} className="ml-1 text-sm">
                Auto pay
              </label>

              <a
                onClick={() => setShow(!show)}
                className="ml-1 text-sm text-indigo-500 font-semibold cursor-pointer"
              >
                learn more
              </a>
            </div>

            {!!autoPay && (
              <p className="text-xs text-gray-600 py-2">
                The plan you selected will charge your card on a{" "}
                {currentDueLevel.interval} schedule.
              </p>
            )}

            {show && (
              <aside className="mt-1 p-2 bg-gray-50 text-gray-600 text-sm">
                By enabling auto pay, we'll take care of your recurring payment
                automatically, so you don't have to worry about it.
                <br />
                We'll use the same credit card you are paying with today, and you
                can cancel your auto-pay at any time.
              </aside>
            )}
          </>
      )}
    </>
  );
};

export const BaseErrors = () => {
  const {
    state: { errors },
  } = useForm();

  const baseErrors = errors["base"] || [];

  return (
    <>
      {baseErrors.map((e, i) => {
        return (
          <p
            key={i}
            className="text-red-600"
            dangerouslySetInnerHTML={{ __html: e }}
          />
        );
      })}
    </>
  );
};
