import React, { createContext, useContext, useRef, useState } from "react";
import { createStore, useStore } from 'zustand'
import ReactToolTip from 'react-tooltip';
import { generateId } from '../utils/genIds';
import InvoiceScheduleForm from './InvoiceScheduleForm';
import { Radio, RadioGroup, Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

export const FormContext = createContext()

const duesCalculationOptions = [
  { id: "flatrate", title: 'Flatrate', description: 'all members pay the same dues amount' },
  { id: "individual", title: 'Individual', description: 'each member pays a different amount' }
]

const newScheduledReminder = {
  name: 'Dues Reminder',
  reminder_type: "overdue",
  interval: "monthly",
  delivery_method: "email",
  status: "active",
  days_before_or_after: 1,
  time_of_day: '12:00',
  time_zone: 'Eastern Time (US & Canada)',
  message: 'Your invoice is overdue.',
  email_subject: 'Your Union dues are overdue.'
}

const createFundStore = (initialProps) => {
  const defaultProps = {
    invoicingFund: {},
    dueDayOptions: [],
    scheduledReminders: [],
    duesCalculationOptions: duesCalculationOptions
  }
  const selectedDuesCalculation = duesCalculationOptions.find(duesCalculation => duesCalculation.id === initialProps.invoicingFund.dues_calculation_type)
  return createStore()(set => (
    {
      ...defaultProps,
      ...initialProps,
      setSelectedDuesCalculation: (duesCalculation) => set({ selectedDuesCalculation: duesCalculation }),
      setAutoRemind: (autoRemind) => set((state) => ({ invoicingFund: { ...state.invoicingFund, auto_remind: autoRemind } })),
      setAutoInvoice: (autoInvoice) => set((state) => ({ invoicingFund: { ...state.invoicingFund, auto_invoice: autoInvoice } })),
      selectedDuesCalculation: selectedDuesCalculation,
    }))
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CreateInvoiceFundFormProvider = (props) => {
  const formStore = useRef(createFundStore(props)).current
  return (
    <FormContext.Provider value={formStore}>
      <CreateInvoiceFundForm />
    </FormContext.Provider>
  )
}
const CreateInvoiceFundForm = () => {
  const store = useContext(FormContext)
  const { invoicingFund, organization, dueDayOptions, scheduledReminders, selectedDuesCalculation } = useStore(store)
  return (
    <div className="">
      {invoicingFund.id && <input type="hidden" name="fund[id]" value={invoicingFund.id} />}
      {invoicingFund.id && <input type="hidden" name="_method" value="put" />}
      <input type="hidden" name="fund[status]" value="active" />
      {organization.id && <input type="hidden" name="fund[organization_id]" value={organization.id} />}
      <input type="hidden" name="fund[fund_type]" value="invoice" />
      <input type="hidden" name="fund[interval]" value="monthly" />
      <div className="">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="px-4 col-span-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <TextInput label="Membership Name" required type="text" name="fund[name]" defaultValue={invoicingFund.name} />

            <div className=" col-span-6 ">
              <RadioInputs />
            </div>
            <div className="overflow-hidden p-1 col-span-3">
              <Transition
                as="div"
                show={selectedDuesCalculation.id === 'flatrate'}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
              >
                <div className=" col-span-6 ">
                  <TextInput
                    label="Dues Amount"
                    required
                    toolTip="This is the dollar amount that all members will pay"
                    type="text"
                    name="fund[amount]"
                    defaultValue={invoicingFund.amount} />
                </div>
              </Transition>
            </div>

            <div className=" col-span-6 ">
              <TextInput
                label="Receipt Message"
                description="Add a short message that will appear on the receipt email"
                type="textarea"
                name="fund[receipt_text]"
                defaultValue={invoicingFund.receipt_text} />
            </div>


            <div className=" col-span-6 ">
              <InvoiceScheduleForm
                invoicing_fund={invoicingFund}
                scheduled_reminders={scheduledReminders}
                due_day_options={dueDayOptions}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

const TextInput = (props) => {
  const { label, required, type, name, defaultValue, onChange, toolTip, description, options } = props
  const id = generateId()
  let fundsTypeOptions = [];
  if (type === 'select') {
    fundsTypeOptions = Object.keys(options).map((e, i) => {
      return (
        <option key={i} value={options[e]}>
          {e.split('_').join(' ')}
        </option>
      );
    });
  }

  return (
    <div className="sm:col-span-3">
      <label
        htmlFor={id}
        className="block text-sm font-semibold leading-6 text-gray-900" >
        {label}
        {!!required && '*'}
        {!!toolTip && <ToolTip dataForId={id} dataTip={toolTip} />}
      </label>
      {!!description && (
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      )}
      <div className={`mt-1 relative rounded-md ${type === "checkbox" ? "" : "shadow-sm"}`}>

        {type === "select" && (
          <select
            required={required}
            id={id}
            name={name}
            defaultValue={defaultValue}
            onChange={onChange}
            className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:ring-indigo-500 "
          >
            {fundsTypeOptions}
          </select>
        )}

        {type === "text" && (
          <input
            required={required}
            id={id}
            type={type}
            name={name}
            step={type == "number" ? "0.01" : null}
            defaultValue={defaultValue}
            onChange={onChange}
            className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 disabled:bg-gray-100 disabled:opacity-50 focus:ring-indigo-500"
          />
        )}

        {type === "textarea" && (
          <textarea
            required={required}
            id={id}
            name={name}
            defaultValue={defaultValue}
            onChange={onChange}
            className="form-input block w-full focus:ring-indigo-500  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        )}
      </div>
    </div>
  )
}

const ToolTip = (props) => {
  const { dataForId, dataTip } = props;

  return (
    <>
      <span data-for={dataForId} data-tip={dataTip} className="ml-1">
        <svg viewBox="0 0 20 20" className="w-4 h-4 inline-block">
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <ReactToolTip
          multiline
          id={dataForId}
          type="info"
          place="top"
          effect="solid"
        />
      </span>
    </>
  );
};



const RadioInputs = (props) => {
  const store = useContext(FormContext)
  const { duesCalculationOptions, selectedDuesCalculation } = useStore(store)
  const setSelectedDuesCalculation = useStore(store, (s) => s.setSelectedDuesCalculation)

  return (
    <fieldset>
      <legend className="text-sm font-semibold leading-6 text-gray-900">Select a Way to Calculate Dues</legend>
      <input type="hidden" name="fund[dues_calculation_type]" defaultValue={selectedDuesCalculation.id} />
      <RadioGroup
        value={selectedDuesCalculation}
        onChange={setSelectedDuesCalculation}
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
      >
        {duesCalculationOptions.map((duesCalculation) => (
          <Radio
            key={duesCalculation.id}
            value={duesCalculation}
            aria-label={duesCalculation.title}
            aria-description={`${duesCalculation.description}`}
            className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600"
          >
            <span className="flex flex-1">
              <span className="flex flex-col">
                <span className="block text-sm font-medium text-gray-900">{duesCalculation.title}</span>
                <span className="mt-1 flex items-center text-sm text-gray-500">{duesCalculation.description}</span>
              </span>
            </span>
            <CheckCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-indigo-600 [.group:not([data-checked])_&]:invisible"
            />
            <span
              aria-hidden="true"
              className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
            />
          </Radio>
        ))}
      </RadioGroup>
    </fieldset>
  )
}

export default CreateInvoiceFundFormProvider;